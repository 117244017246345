<template>
   <div id="underCon" class="Content-Page">
      <div class="wrap-Main">
         <img src="../../public/images/undercon.svg" />
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>